import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { AboutUsComponent } from "./about-us/about-us.component";
import { MediaComponent } from "./media/media.component";
import { EventsComponent } from "./events/events.component";
import { ContactsComponent } from "./contacts/contacts.component";
import { ServicesComponent } from "./services/services.component";

const routes: Routes = [
  {
    path: "home",
    component: HomeComponent,
  },
  {
    path: "about-us",
    component: AboutUsComponent,
  },
  {
    path: "media",
    component: MediaComponent,
  },
  {
    path: "event",
    component: EventsComponent,
  },
  {
    path: "contacts",
    component: ContactsComponent,
  },
  {
    path: "services",
    component: ServicesComponent,
  },
  {
    path: "",
    redirectTo: "/home",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
