import { Component } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "bristol-church-website";
  pageId = 1;
  email = new FormControl("", [Validators.required, Validators.email]);

  getErrorMessage() {
    if (this.email.hasError("required")) {
      return "You must enter a valid email";
    }

    return this.email.hasError("email") ? "Not a valid email" : "";
  }

  onActivate(event: any) {
    this.pageId = event.pageId;
  }
}
