import { Component } from "@angular/core";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent {
  readonly pageId = 1;

  readonly text =
    "Know therefore that the Lord your God is God; he is the faithful " +
    "God, keeping his covenant of love to a thousand generations of those " +
    "who love him and keep his commandments.";
  readonly subText = "Deuteronomy 7:9";

  readonly section2Text = `Jesus says ”Therefore go and make disciples of all nations, baptizing
  them in the name of the Father and of the Son and of the Holy Spirit,
  and teaching them to obey everything I have commanded you. And surely I
  am with you always, to the very end of the age.” We have taken upon the
  great commission and welcome you to our congregation.`;

  readonly card1Title = `ABOUT OUR CHURCH`;
  readonly card1Text = `Our church is on a mission to save the people`;

  readonly card2Title = `MEDIA`;
  readonly card2Text = `We have a selection of Sermons from various Pastors and men and women of God that can be accessed at a moments notice.`;

  readonly card3Title = `SERVICES`;
  readonly card3Text = `If you would like to attend one of our meetings please view our days and
  timings. We look forward to seeing you!`;
}
