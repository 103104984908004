import { Component } from "@angular/core";
import { Event } from "../core/interfaces";

const MockEvents: Event[] = [
  {
    name: "Church Sunday School and Youth Anniversary",
    description:
      "A day to celebrate the younger generations and praise them for their work and gift them.",
    time: "Time is TBD",
    venue: "Kingsholm Church",
    displayImg: "../assets/event_1.webp",
  },
  {
    name: "Church Outing",
    description:
      "A time of laughter and joy for all going places together as a family.",
    time: "Time is TBD",
    venue: "Kingsholm Church",
    displayImg: "../assets/event_2.webp",
  },
  {
    name: "Annual Fasting Prayer",
    description:
      "This is a yearly experience that spans over three days and is a time of spiritual growth.",
    time: "Time is TBD",
    venue: "Kingsholm Church",
    displayImg: "../assets/event_3.webp",
  },
  {
    name: "Convention 2020",
    description:
      "The first convention of the church and will provide a new and powerful experience for the believer.",
    time: "Fri, 30 Oct",
    venue: "Kingsholm Church",
    displayImg: "../assets/event_1.webp",
  },
];

@Component({
  selector: "app-events",
  templateUrl: "./events.component.html",
  styleUrls: ["./events.component.scss"],
})
export class EventsComponent {
  readonly pageId = 5;
  // Temporary Events need to remove while integrating with API
  readonly events: Event[] = MockEvents;
}
